.container-height-scroll-bar {
    height: 78vh;
    overflow: auto;
}

.status-open-color {
    background-color: green;
}

.status-close-color {
    background-color: gray;
}

.status-cancel-color {
    background-color: #db2828;
}

.status-printed-color {
    background-color: #d1a641;
}

.element-sticky {
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
}

.filter__dash {
    font-size: 20px;
    padding-right: 14px;
}

.filter__dropdown {
    width: 100% !important;
    min-width: auto !important;
}

.filter__width {
    width: 50% !important;
}

.filter__width .react-datepicker-wrapper {
    width: 100% !important;
}
