.sale-page__category-item {
    width: 33%;
    height: 25%;
    margin: 0 !important;
    float: left;
    cursor: pointer;
}

.sale-page__category-item:hover {
    color: #00b5ad;
    background-color: #e4e9eb;
}

.sale-page__font-size-15 {
    font-size: 14px;
}

.sale-page__font-size-10 {
    font-size: 10px;
}

.sale-page__wrap-text {
    inline-size: 100%;
    overflow-wrap: break-word;
    font-size: 17px;
}

.sale-page__add-category-item {
    position: absolute;
    top: 40%;
    left: 44%;
}