.orders-page__padding-top-bottom {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.orders-page__order {
  cursor: pointer;
}

.orders-page__order:hover {
  color: #00b5ad;
  background-color: #e4e9eb;
}

.orders-page__order-selected {
  color: #00b5ad !important;
  background-color: #e4e9eb !important;
}

.orders-page__price {
  font-size: 18px;
  font-weight: bold;
}

.orders-page__date-time {
  font-size: 10px;
  font-weight: bold;
}

.orders-page__status {
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  color: white;
  border-radius: 10px;
}

.orders-page__segment-column {
  padding: 0 !important;
}

.orders-page__segment-row {
  padding: 5px 5px 5px 5px !important;
}