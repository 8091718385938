.settings-page__sign-out {
    text-align: center;
    color: rgba(228, 34, 34, 0.747) !important;
}

.settings-page__subscription {
    text-align: center;
    color: rgba(34, 118, 228, 0.747) !important;
    font-weight: bold !important;
}

.settings-page__top-segment {
    height: 70vh !important;
}

.settings-page__bottom-segment {
    height: 8vh !important;
}

.settings-page__save-button {
    margin-top: -2vh !important;
}