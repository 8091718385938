.auth-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.auth-page__logo-block {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-page__form-block {
    margin-top: -40px;
}

.auth-page__grid-block {
    width: 100%;
}

.auth-page__center-align {
    text-align: center;
}

.auth-page__margin-top-30 {
    margin-top: 30px;
}

.auth-page__login-button {
    width: 300px;
}

.auth-page__login-ancher {
    color: #00b5ad;
}

.auth-page__register-button {
    width: 200px;
}

.auth-page__pointer {
    cursor: pointer;
}

.auth-page__business-info {
    text-align: center;
    font-size: x-small;
    margin-top: 30px;
    color: gray;
}