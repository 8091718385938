.pin-pad__button {
    width: 32%;
    font-size: 25px !important;
}

.pin-pad__grid-row {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.pin-pad__pin {
    font-size: 4.0rem;
    font-weight: bold;
    height: 3.5rem;
}

.pin-pad__error {
    margin-bottom: 0 !important;
    font-size: 10px !important;
    text-align: center;
}