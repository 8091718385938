.menu-page__business-info {
    padding: 0px 10px 0px 0px !important;
    text-align: end !important;
}

.menu-page__menu-container {
    margin: 0 !important;
    height: 85px !important;
}

.menu-page__right-menu {
    width: 15% !important;
}

.menu-page__right-menu-item {
    width: 100% !important;
}