.reports-page__filter-block {
    display: flex;
    justify-content: space-between;
}

.reports-page__date-filter {
    justify-content: flex-start;
    z-index: 2 !important;
}

.reports-page__chart-export {
    justify-content: flex-end;
}

.reports-page__table {
    margin-top: 0 !important;
}