.info-top-container {
    position: relative;
}

.info-background-image {
    width: 100%;
    height: 100%;
}

.info-description-row {
    padding: 0 15% 0 15% !important;
}

.info-description-image {
    width: 100%;
    height: 90%;
}

.info-description-title {
    font-weight: bold;
    font-size: 1.4vw;
    margin-bottom: 5%;
}

.info-description-text {
    font-size: 1.2vw;
}

.info-description-align-end {
    text-align: end;
}

.info-description-text-block {
    position: absolute !important;
    top: 45% !important;
    transform: translate(0, -50%) !important;
}

.info-button__block {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.info-button {
    font-size: 300% !important;
}

.info-demo-block {
    padding-top: 10px;
    font-size: large;
}

.info-demo-text {
    padding-bottom: 5px;
}

.info-contact-block {
    text-align: center !important;
    margin-top: 10% !important;
}

.info-description-column-width {
    width: 50% !important;
}

.info-description-display-in-large-screen {
    display: block !important;
}

.info-description-display-in-small-screen {
    display: none !important;
}

@media only screen and (max-width: 700px) {
    .info-button {
        font-size: 80% !important;
    }

    .info-demo-block {
        padding-top: 5px;
        font-size: x-small;
    }

    .info-demo-text {
        padding-bottom: 0;
    }

    .info-description-title {
        font-size: small;
    }
    
    .info-description-text {
        font-size: x-small;
    }

    .info-description-column-width {
        width: 100% !important;
    }

    .info-description-text-block {
        position: static !important;
        top: 0 !important;
        transform: translate(0, 0) !important;
    }

    .info-description-display-in-large-screen {
        display: none !important;
    }
    
    .info-description-display-in-small-screen {
        display: block !important;
    }

    .info-description-row {
        margin-top: 10% !important;
    }
}