.order-detail-page__card {
    width: 100% !important;
    height: 100%;
}

.order-detail-page__item-content {
    height: 90vh;
    overflow: auto;
}

.order-detail-page__detail-block {
    display: flex;
    justify-content: space-between;
}

.order-detail-page__detail-title {
    justify-content: flex-start;
}

.order-detail-page__detail-price {
    justify-content: flex-end;
}

.order-detail-page__detail-delete {
    font-size: 10px !important;
    margin-left: 5px !important;
}

.order-detail-page__total-content {
    height: 33%;
    overflow: auto;
}

.order-detail-page__pedding-row {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.order-detail-page__text-align {
    text-align: end !important;
}

.order-detail-page__button-bar {
    height: 8vh !important;
}

.order-detail-page__button-font-size {
    font-size: 14px !important;
    height: 85% !important;
}

.order-detail-page__button-content {
    padding-left: 1px !important;
    padding-right: 1px !important;
    height: 20% !important;
}

.order-detail-page__item-quantity {
    font-size: 10px;
    margin-right: 10px;
}

.order-detail-page__side-menu {
    font-size: 9px !important;
}

.order-detail-page__popup {
    min-width: fit-content !important;
}

.checkout-page__balance {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.checkout-page__color-red {
    color: red;
}

.checkout-page__input {
    width: 85%;
    margin-right: 15px;
    margin-bottom: 5px;
}

.checkout-page__amount {
    margin-bottom: 0 !important;
}

.checkout-page__amount-buttons {
    margin-bottom: 10px;
}